
.gallery {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 1em;    
}

.gallery .ape {
    margin: 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.gallery .ape img {    
    z-index: 0;
}

.gallery img {
    width: 100%;
    border-radius: 5%;
    display: inherit;
}

@media screen and (max-width: 992px) {
    /* Small Display */
    .gallery { 
        grid-template-columns: repeat(4, 1fr);
    }
}