/* Reset basics */

*{
    margin:0px;
    padding: 0px;
    box-sizing: border-box;
}

body {
    color: white;
    background-color: #090221;
    font-family: 'Roboto', sans-serif;
}


h2 {
    font-family: 'Orbitron', sans-serif;
    font-size: 2.5rem;
    padding-bottom: 1.5rem;
    padding-top: 2.5rem;

}
/* ------------------------------------------ */
/* --------------- NAVIGATION --------------- */
/* ------------------------------------------ */

nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-height: 8vh;
    background-color: #050113;

    margin-top: 0.5em;

}

.logo{
    color: rgb(219, 219, 219);
    padding-top: 10px;
    padding-bottom: 10px;
}

.nav-links{
    display: flex;
    justify-content: space-around;
    width: 40%;
    align-items: center;
}

.nav-links li {
    list-style: none;
}

.nav-links a, .nav-links a:visited {
    color: rgb(203, 203, 203);
    text-decoration: none;
    letter-spacing: 1px; 
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    padding: 0.5em 1em;
    transition: all 0.5s ease;
    border-radius: 5px;
}
.nav-links a:hover {
    color: rgb(255, 255, 255);
    background-color:  rgb(47, 47, 47);

}
.burger {
    display: none;
    cursor: pointer;
}
.burger div{
    width: 25px;
    height: 2px;

    background-color: rgb(219, 219, 219);
    margin: 5px;

    transition: all 0.3s ease;
}

.connect-button button {
    background: #2de858;
    border: 1px solid #2bd753;
    color: #000;
    font-size: 1rem;
    border-radius: 12px;
    padding: 10px;
}

.connect-button button:hover{
    background: #092d12;
    color: #fff;
    outline: none;
    cursor: pointer;
}


@media screen and (max-width:768px){
    body{
        overflow-x: hidden;
    }
    .nav-links{
        position: absolute;
        right: 0;
        height: 92vh;
        top: 8vh;
        background-color: #050113;

        display: flex;
        flex-direction: column;
        align-items: center;
        width: 50%;
        transform: translateX(100%);
        transition: transform 0.5s ease-in;
    }

    .burger{
        display: block;
    }
}

.nav-active{
    transform: translateX(0);
}


@keyframes navLinkFade{
    from{
        opacity: 0;
        transform: translateX(50px);
    }
    to{
        opacity: 1;
        transform: translateX(0px);
    }
}


.toggle-burger .line1 {
    transform: rotate(-45deg) translate(-4px, 6px);
}
.toggle-burger .line2 {
    opacity: 0;
}
.toggle-burger .line3 {
    transform: rotate(45deg) translate(-4px, -6px);
}


/* ------------------------------------ */
/* --------------- BODY --------------- */
/* ------------------------------------ */

.wrapper {
    display: flex;
    flex-direction: column;

    /*background-color: #13010e; cool gb color*/
    background-color: #090221;
}

.grid-classic-body{
    margin: 3rem auto; /* centers */ 
    width: min(90%,75rem);
    display: grid;
}

/* --------------- HERO --------------- */
.grid-hero{
    min-height: 10vh;
    font-size: 1.2rem;
    /*background: linear-gradient(90deg, rgba(9,2,33,1) 0%, rgba(28,15,74,1) 43%, rgba(0,212,255,0) 100%);*/
    background-image: url("images/bg-hero-awl-1200.png");
    background-position: bottom; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */

    margin: 2rem auto; /* centers */ 
    width: min(90%,78rem);


    display: grid;
    grid-template-columns: 50% 50%;
    
}



.txt-hero{
    padding-top: 5rem;
    padding-right: 2rem;
    /*background: rgba(9,2,33,0.5);*/
    background: linear-gradient(90deg, rgba(5,1,19,0.9) 0%, rgba(255,255,255,0) 100%);
    
}
.grid-hero p{
    padding: 1.2rem;
}
.img-hero{
    padding-top: 2rem;
    padding-top: 10rem;
    padding-bottom: 1rem;
}

#tutorial{
    float: right;
}


@media screen and (max-width:768px){
    .grid-hero{
        grid-template-columns: none;
        grid-auto-flow: row;
    }

    .txt-hero{
    padding-right: 8rem;
    }

    .banana-section{
        display: none;
    }
}


/* ---------- MINT BUTTONS ------------ */


.mint-form small {
    font-size: 0.4em;
}

.mint-form {
    font-family: 'Orbitron', sans-serif;
    font-size: 1.8rem; 
    display: flex;
    padding: 1.2rem;
}

.inputNumber {
    display: flex;
    margin-right: 20px;
}

.inputNumber input {
    background: none;
    border: 1px solid #FFFA;
    
    color:rgb(254, 224, 3);
    font-size: 1.8rem;
    width: 100px;
    font-family: 'Orbitron';
    text-align: center;
    margin-right: 5px;
}

.mint-form .inputNumber button {
    padding: 10px;
    border: 1px solid #FFFA;
    color: #FFFA;
    margin-right: 5px;
    
}

.mint-form button {
    background: none;
    border: 2px solid rgb(254, 224, 3);
    color:rgb(254, 224, 3);
    font-family: 'Orbitron';
    font-size: 1rem;
    padding: 10px 50px;
}

.mint-form button:hover {
    background: rgb(254, 224, 3);
    color: #333;
    outline: none;
}

.successMint {
    margin-left: 1.2rem;
    padding: 1.2rem;
    background-color: #2bd753;
}

.txtConnectWallet{
    margin-left: 1.2rem;
    padding: 1.2rem;
    background-color: #120086;
    background-color: #348755;
    font-style: italic;
}

@media screen and (max-width:768px){
    .mint-form{
        display: flex;
        flex-direction: column;
    }
    .inputNumber input {
        font-size: 1.2rem;
        width: 4rem;
    }
    .mint-btn{
        padding-top: 1rem;
    }
}

/* --------------- BANANAS --------------- */
.banana-section {
    margin: 0rem auto; /* centers */ 
    width: min(90%,75rem);
}

.x img, .x {
  width: 80px;
  height: 80px;
}

.x {
  animation: x 7s linear infinite alternate;
}

.y {
  animation: y 7s linear infinite alternate;
}

@keyframes x {
  100% {
    transform: translateX( calc(1200px - 80px) );
  }
}

@keyframes y {
  100% {
    transform: translateY( calc(200px - 80px) );
  }
}



/* --------------- PARAGRAPHS --------------- */
.paragraphs{
    min-height: 10vh;
    font-size: 1rem;

    margin: 2rem auto; /* centers */ 
    width: min(90%,75rem);

    display: grid;
    grid-template-columns: 70% 30%;
}

.paragraph_nogrid{
    display:block;

    
}


.paragraphs p{
    line-height: 1.5rem;
    padding-bottom: 1rem;
}
img.bottom {
    vertical-align: text-bottom;
  }
.paragraphs img{
    max-width: 100%;
}



/* --------------- FREE THE APES --------------- */
.free-ape {
    margin-top: 5rem;
    text-align: center;
}
.free-ape img{
    width: 140px;
}


/* -------------------- FOOTER -------------------- */
.footer{
    min-height: 10vh;
    background-color: #2587e1;
    background-color: #000;
}

.footer a, .footer a:visited{
    color: #fff;
}
.footer a:hover{
    color: rgb(151, 255, 191);
}

.bgfooter{

    background-image: url("images/footer-bg.png");
    min-height: 160px;

    background-position: center; /* Center the image */
    background-position: top; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
}

.grid_footer{
    color: rgb(219, 219, 219);
    /*background-color: rgba(255, 0, 0, 0.2);*/


    margin: 0rem auto; /* centers */
    padding-top: 3.5rem;
    width: min(90%,75rem);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.socials-footer{
    /*background-color: rgba(76, 0, 255, 0.2);*/
    text-align: center;
    padding-top: 1rem;
}

.twitterIco{
    margin-right: 2rem;
}