.grid-items{
    /* background: #ffffff65; */
    width: min(95%);
    display: grid;
    grid-template-columns:  25% 25% 25% 25%;
    gap: 1em 1em;
}

.grid-items > div {

  }

.photo{
    font-weight: bold;
    font-size: 1.2rem;
    text-transform: uppercase;
    
}

.photo img{
    width: 100%;
    border-radius: 5%;
}
